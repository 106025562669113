import {useCallback, useEffect, useState} from 'react';
import {VIEWED_JOBS_COOKIE} from '~/shared/constants/cookies';
import {useSupabase} from '~/shared/hooks/use-supabase';
import Cookies from 'js-cookie';
import {usePathname, useRouter} from 'next/navigation';

interface ViewedJobsData {
	userId?: string; // Optional - present for authenticated users
	jobIds: number[];
}

export const useViewedJobs = () => {
	const router = useRouter();
	const pathname = usePathname();
	const {user} = useSupabase();

	const getViewedJobs = useCallback((): number[] => {
		const savedData = Cookies.get(VIEWED_JOBS_COOKIE);
		if (savedData) {
			try {
				const data: ViewedJobsData = JSON.parse(savedData);
				// Only return jobs if they belong to this user (or no user for anonymous)
				if ((!data.userId && !user) || data.userId === user?.id) {
					return data.jobIds;
				}
			} catch {
				// Safe to ignore
			}
		}
		return [];
	}, [user]);

	const [viewedJobs, setViewedJobs] = useState<number[]>(getViewedJobs);

	useEffect(() => {
		setViewedJobs(getViewedJobs());
	}, [pathname, getViewedJobs]);

	const addViewedJob = useCallback(
		(jobId: number) => {
			const currentViewedJobs = getViewedJobs();
			if (jobId && !currentViewedJobs.includes(jobId)) {
				const updatedViewedJobs = [...currentViewedJobs, jobId];
				const cookieData: ViewedJobsData = {
					userId: user?.id, // Will be undefined for anonymous users
					jobIds: updatedViewedJobs,
				};

				setViewedJobs(updatedViewedJobs);
				Cookies.set(VIEWED_JOBS_COOKIE, JSON.stringify(cookieData), {
					expires: 30,
					sameSite: 'lax',
				});
				router.refresh();
			}
		},
		[getViewedJobs, user, router],
	);

	const isJobViewed = useCallback((id: number) => viewedJobs.includes(id), [viewedJobs]);

	return {
		addViewedJob,
		isJobViewed,
		viewedJobs,
	};
};
