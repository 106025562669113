import(/* webpackMode: "eager", webpackExports: ["ColorMode"] */ "/vercel/path0/apps/web/src/app/_/components/color-mode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseListener"] */ "/vercel/path0/apps/web/src/app/_/components/supabase-listener.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/assets/styles/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/shared/providers/hydration-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/vercel/path0/apps/web/src/shared/providers/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseProvider"] */ "/vercel/path0/apps/web/src/shared/providers/supabase-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/vercel/path0/apps/web/src/shared/providers/toast-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.62.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["LazyMotion","domAnimation"] */ "/vercel/path0/node_modules/.pnpm/framer-motion@11.16.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/framer-motion/dist/es/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts/inter.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts/karla.ts\",\"import\":\"Karla\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-karla-mono\",\"display\":\"swap\"}],\"variableName\":\"karla\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@3.7.15_next@15.1.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18._pffe6ztwtf6gqzdssbmew2octy/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/lib/hooks/index.ts");
