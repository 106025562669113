export const getEnvironmentBaseUrl = () => {
	let url =
		process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL ||
		process.env.NEXT_PUBLIC_SITE_URL ||
		process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL ||
		process.env.NEXT_PUBLIC_VERCEL_URL ||
		`http://localhost:${process.env.NEXT_PUBLIC_PORT || 3000}`;

	if (!url.startsWith('http://') && !url.startsWith('https://')) {
		url = `https://${url}`;
	}

	try {
		const normalizedUrl = new URL(url);
		return normalizedUrl.toString().endsWith('/') ? normalizedUrl.toString() : `${normalizedUrl.toString()}/`;
	} catch {
		return url;
	}
};

export const isValidUrl = (url?: string) => {
	try {
		if (!url) return false;
		return Boolean(new URL(url));
	} catch {
		return false;
	}
};
