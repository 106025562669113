import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDebounce} from 'react-use';
import type {DeleteCompanyAlertProps} from '~/shared/components/alerts/delete-company';
import {DeleteCompanyAlert} from '~/shared/components/alerts/delete-company';
import {CompanyLogo} from '~/shared/components/company-logo';
import type {CompanyDialogProps} from '~/shared/components/dialogs/company';
import {CompanyDialog} from '~/shared/components/dialogs/company';
import {useCompanies, useUpsertCompany} from '~/shared/data/company';
import {useJobBoardCompanies} from '~/shared/data/job-board';
import {ButtonGroup, Combobox, IconButton, Separator, TextField} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconBuildingSkyscraper, IconEdit, IconPlus, IconSelector, IconTrash} from '@tabler/icons-react';
import clsx from 'clsx';

import type {CompanyRead} from '@job-ish/job-data-api/client/job-data-api';

type CompanySelectProps = {
	value?: number;
	onValueChange: (value?: number | null) => void;
	readonly?: boolean;
};

export const CompanySelect = ({value, onValueChange, readonly}: CompanySelectProps) => {
	const [companySearch, setCompanySearch] = useState('');
	const [debouncedCompanySearch, setDebouncedCompanySearch] = useState('');
	const [open, setOpen] = useState(false);

	useDebounce(
		() => {
			setDebouncedCompanySearch(companySearch);
		},
		200,
		[companySearch],
	);

	const {show: showDialog} = useModal();

	const {data: companies, isLoading: isLoadingCompanies} = useJobBoardCompanies(debouncedCompanySearch);
	const {data: savedCompanies} = useCompanies();
	const {mutateAsync: upsertCompany} = useUpsertCompany();

	const handleSelectExternalCompany = useCallback(
		async (company: CompanyRead) => {
			const res = await upsertCompany({
				external_id: company.id,
				name: company.name,
				website: company.website_url,
				logo: company.logo,
			});

			if (res) {
				onValueChange(res.id);
			}
		},
		[onValueChange, upsertCompany],
	);

	const customCompanies = useMemo(
		() => savedCompanies?.filter(c => c.external_id === null),
		[savedCompanies],
	);

	const filteredCustomCompanies = useMemo(
		() =>
			customCompanies?.filter(company =>
				company.name.toLowerCase().includes(debouncedCompanySearch.toLowerCase()),
			),
		[customCompanies, debouncedCompanySearch],
	);

	const selectedCompany = useMemo(
		() => savedCompanies?.find(company => company.id === value),
		[savedCompanies, value],
	);

	const empty = useMemo(
		() =>
			!isLoadingCompanies &&
			(!companies?.data.results || companies?.data.results.length === 0) &&
			!customCompanies?.length,
		[companies?.data.results, customCompanies?.length, isLoadingCompanies],
	);

	useEffect(() => {
		if (value && !selectedCompany) {
			onValueChange(null);
		}
	}, [selectedCompany, value, onValueChange]);

	return (
		<ButtonGroup className="flex w-full items-center">
			<Combobox onOpenChange={setOpen} open={open}>
				<Combobox.Trigger className="min-w-0 grow" disabled={empty || readonly}>
					<TextField
						aria-label="Company name display"
						className={clsx('w-full [&>input]:truncate', !readonly && 'rounded-r-none')}
						clearable
						disabled={empty || readonly}
						forceShowClearButton
						key={`${!!selectedCompany}`}
						onChange={value => onValueChange(value ? Number(value) : null)}
						placeholder="Select a company"
						prefix={
							selectedCompany ? (
								<CompanyLogo company={selectedCompany} />
							) : (
								<IconBuildingSkyscraper className="h-4 w-4" />
							)
						}
						readonly
						suffix={<IconSelector className="h-4 w-4" />}
						value={selectedCompany?.name}
					/>
				</Combobox.Trigger>
				<Combobox.Content align="start" className="z-10 ml-[1px] w-80" portal={false} shouldFilter={false}>
					<Combobox.Input
						loading={isLoadingCompanies}
						onValueChange={setCompanySearch}
						placeholder="Search companies..."
						value={companySearch}
					/>
					<Combobox.Empty>
						{isLoadingCompanies ? 'Loading companies...' : 'No companies found.'}
					</Combobox.Empty>
					<Combobox.List className="max-h-[12rem]">
						{filteredCustomCompanies && filteredCustomCompanies.length > 0 && (
							<>
								<Combobox.Group>
									<Combobox.Label>Custom Companies</Combobox.Label>
									{filteredCustomCompanies.map(company => (
										<Combobox.Item
											checked={company.id === selectedCompany?.id}
											icon={() => <CompanyLogo company={company} />}
											key={company.id}
											onSelect={() => onValueChange(company.id)}
											value={company.name.toString()}
										>
											<div className="truncate">{company.name}</div>
										</Combobox.Item>
									))}
								</Combobox.Group>
								{companies?.data.results && companies.data.results.length > 0 && (
									<Separator className="w-full" orientation="horizontal" />
								)}
							</>
						)}
						{companies?.data.results && companies.data.results.length > 0 && (
							<Combobox.Group>
								{companies?.data.results?.map(company => (
									<Combobox.Item
										checked={company.id === selectedCompany?.external_id}
										icon={() => <CompanyLogo company={company} />}
										key={`external-${company.id}`}
										onSelect={() => handleSelectExternalCompany(company)}
										value={`external-${company.id}`}
									>
										<div className="truncate">{company.name}</div>
									</Combobox.Item>
								))}
							</Combobox.Group>
						)}
					</Combobox.List>
				</Combobox.Content>
			</Combobox>
			{!readonly && !selectedCompany?.external_id && (
				<>
					{value ? (
						<>
							<IconButton
								icon={IconEdit}
								onPress={() => showDialog<CompanyDialogProps>(CompanyDialog, {company: selectedCompany})}
							/>
							<IconButton
								icon={IconTrash}
								onPress={() =>
									selectedCompany &&
									showDialog<DeleteCompanyAlertProps>(DeleteCompanyAlert, {
										company: selectedCompany,
										onDeleted: () => onValueChange(null),
									})
								}
							/>
						</>
					) : (
						<IconButton
							icon={IconPlus}
							onPress={() => showDialog<CompanyDialogProps>(CompanyDialog, {onCompanyAdded: onValueChange})}
						/>
					)}
				</>
			)}
		</ButtonGroup>
	);
};
