import {useRef} from 'react';
import {useEffectOnce, useMedia} from 'react-use';
import {Logo} from '@job-ish/blocks/components';
import {IconButton, Sheet} from '@job-ish/ui/components';
import {IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand} from '@tabler/icons-react';
import clsx from 'clsx';

import {NavigationList} from './navigation-list';

type SidebarProps = {
	open?: boolean;
	onOpenChange: (open: boolean) => void;
};

export const Sidebar = ({open, onOpenChange}: SidebarProps) => {
	const modal = useMedia('(max-width: 768px)', false);
	const triggerRef = useRef<HTMLButtonElement>(null);

	useEffectOnce(() => {
		if (open !== undefined && triggerRef.current) {
			triggerRef.current.focus();
		}
	});

	return (
		<Sheet modal={modal} onOpenChange={onOpenChange} open={open}>
			{!open && (
				<div className="hidden flex-col bg-mauve2 px-1.5 py-2.5 md:flex">
					<IconButton
						className="mx-auto h-12 w-12"
						icon={IconLayoutSidebarLeftExpand}
						intent="ghost"
						onPress={() => onOpenChange(true)}
					/>
					<div className="py-1">
						<NavigationList iconsOnly />
					</div>
				</div>
			)}
			<div className={clsx(open && 'absolute w-56 min-w-[14rem] md:relative')}>
				<Sheet.Content
					blur={modal}
					className="w-56 min-w-[14rem] px-1.5 ring-0"
					closeTrigger={
						<IconButton className="h-12 w-12" icon={IconLayoutSidebarLeftCollapse} intent="ghost" />
					}
					onEscapeKeyDown={event => !modal && event.preventDefault()}
					onInteractOutside={event => !modal && event.preventDefault()}
				>
					<Sheet.Title className="mb-0 flex items-center px-2">
						<div className="h-[42px]">
							<a href="https://job-ish.com" rel="noopener noreferrer" target="_blank">
								<Logo className="absolute top-1" height={50} width={100} />
							</a>
						</div>
					</Sheet.Title>
					<div className="px-1 py-1">
						<NavigationList />
					</div>
				</Sheet.Content>
			</div>
		</Sheet>
	);
};
