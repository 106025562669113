import {useEffect, useMemo, useState} from 'react';
import {useDebounce} from 'react-use';
import {CompanyLogo} from '~/shared/components/company-logo';
import {useJobBoardCompanies} from '~/shared/data/job-board';
import {Combobox, TextField} from '@job-ish/ui/components';
import {IconBuildingSkyscraper, IconSelector} from '@tabler/icons-react';

import type {CompanyRead} from '@job-ish/job-data-api/client/job-data-api';

type CompanySelectProps = {
	value: {
		id?: number;
		name?: string;
	};
	onValueChange: (value?: CompanyRead | null) => void;
};

export const CompanySelect = ({value, onValueChange}: CompanySelectProps) => {
	const [companySearch, setCompanySearch] = useState('');
	const [debouncedCompanySearch, setDebouncedCompanySearch] = useState('');
	const [initialValue] = useState(value.name);

	useDebounce(
		() => {
			setDebouncedCompanySearch(companySearch);
		},
		200,
		[companySearch],
	);

	const {data: initialCompanies} = useJobBoardCompanies(initialValue);

	const {data: companies, isLoading: isLoadingCompanies} = useJobBoardCompanies(debouncedCompanySearch);

	const selectedCompany = useMemo(
		() =>
			[...(initialCompanies?.data?.results ?? []), ...(companies?.data.results ?? [])].find(
				company => company.id === value.id,
			),
		[companies?.data.results, initialCompanies?.data?.results, value],
	);

	const empty = useMemo(
		() => !isLoadingCompanies && (!companies?.data.results || companies?.data.results.length === 0),
		[companies?.data.results, isLoadingCompanies],
	);

	useEffect(() => {
		if (value.id && !selectedCompany) {
			onValueChange(null);
		}
	}, [selectedCompany, value, onValueChange]);

	return (
		<Combobox>
			<Combobox.Trigger className="min-w-0 grow" disabled={empty}>
				<TextField
					aria-label="Company name display"
					className="w-full [&>input]:truncate"
					clearable
					disabled={empty}
					forceShowClearButton
					key={`${!!selectedCompany}`}
					onChange={() => onValueChange(null)}
					placeholder="Select company"
					prefix={
						selectedCompany ? (
							<CompanyLogo company={selectedCompany} />
						) : (
							<IconBuildingSkyscraper className="h-4 w-4" />
						)
					}
					readonly
					suffix={<IconSelector className="h-4 w-4" />}
					value={selectedCompany?.name}
				/>
			</Combobox.Trigger>
			<Combobox.Content
				align="start"
				className="z-10 ml-[1px] w-80"
				portal={false}
				shouldFilter={false}
				side="top"
			>
				<Combobox.Input
					loading={isLoadingCompanies}
					onValueChange={setCompanySearch}
					placeholder="Search companies..."
					value={companySearch}
				/>
				<Combobox.Empty>{isLoadingCompanies ? 'Loading companies...' : 'No companies found.'}</Combobox.Empty>
				<Combobox.List className="max-h-[12rem]">
					{companies?.data.results && companies.data.results.length > 0 && (
						<Combobox.Group>
							{companies?.data.results?.map(company => (
								<Combobox.Item
									checked={company.id === selectedCompany?.id}
									icon={() => <CompanyLogo company={company} />}
									key={`external-${company.id}`}
									onSelect={() => onValueChange(company)}
									value={`external-${company.id}`}
								>
									<div className="truncate">{company.name}</div>
								</Combobox.Item>
							))}
						</Combobox.Group>
					)}
				</Combobox.List>
			</Combobox.Content>
		</Combobox>
	);
};
