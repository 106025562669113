import {createContext, forwardRef, useCallback, useContext, useEffect, useState} from 'react';
import {IconCheck} from '@tabler/icons-react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ElementRef} from 'react';
import Command from '../command';
import DropdownMenu from '../dropdown-menu';
import Popover from '../popover';
import type {CommandItemProps, CommandListProps, CommandProps} from '../command';
import type {PopoverContentProps, PopoverProps} from '../popover';

const ComboboxContext = createContext<{onOpenChange: (open: boolean) => void}>({onOpenChange: () => {}});

export type ComboboxContentProps = PopoverContentProps &
	CommandProps & {
		portal?: boolean;
	};
const ComboboxContent = forwardRef<HTMLDivElement, ComboboxContentProps>(
	(
		{children, loop, label, value, onValueChange, filter, shouldFilter, portal, className, ...props},
		forwardedRef,
	) => (
		<Popover.Content {...props} className={twMerge('p-0', className)} portal={portal} ref={forwardedRef}>
			<Command
				filter={filter}
				label={label}
				loop={loop}
				onValueChange={onValueChange}
				shouldFilter={shouldFilter}
				value={value}
			>
				{children}
			</Command>
		</Popover.Content>
	),
);

export type ComboboxItemProps = Omit<CommandItemProps, 'suffix'> & {checked?: boolean; asChild?: boolean};
const ComboboxItem = forwardRef<ElementRef<typeof Command.Item>, ComboboxItemProps>(
	({children, checked, asChild, onSelect, ...props}, forwardedRef) => {
		const {onOpenChange} = useContext(ComboboxContext);

		const handleSelect = useCallback(
			(value: string) => {
				onSelect?.(value);
				setTimeout(() => {
					onOpenChange(false);
				}, 50);
			},
			[onSelect, onOpenChange],
		);

		return (
			<Command.Item {...props} asChild={asChild} onSelect={handleSelect} ref={forwardedRef}>
				{children}
				{checked && !asChild && <IconCheck className="ml-auto h-4 w-4 shrink-0" />}
			</Command.Item>
		);
	},
);

export type ComboboxListProps = CommandListProps;
const ComboboxList = forwardRef<HTMLDivElement, ComboboxListProps>(
	({children, className, ...props}, forwardedRef) => (
		<Command.List {...props} className={twMerge('max-h-[24rem]', className)} ref={forwardedRef}>
			{children}
		</Command.List>
	),
);

export type ComboboxProps = PopoverProps;
const Combobox = assign(
	({open, onOpenChange, ...props}: PopoverProps) => {
		const [internalOpen, setInternalOpen] = useState(open);

		const handleOpenChange = useCallback(
			(open: boolean) => {
				setInternalOpen(open);
				onOpenChange?.(open);
			},
			[onOpenChange],
		);

		useEffect(() => {
			if (open !== undefined) {
				setInternalOpen(open);
			}
		}, [open]);

		return (
			<ComboboxContext.Provider value={{onOpenChange: handleOpenChange}}>
				<Popover {...props} onOpenChange={handleOpenChange} open={internalOpen} />
			</ComboboxContext.Provider>
		);
	},
	{
		Trigger: Popover.Trigger,
		Content: ComboboxContent,
		Input: Command.Input,
		List: ComboboxList,
		Empty: Command.Empty,
		Group: Command.Group,
		Separator: Command.Separator,
		Item: ComboboxItem,
		Label: DropdownMenu.Label,
	},
);

Combobox.Trigger.displayName = 'Combobox.Trigger';
Combobox.Content.displayName = 'Combobox.Content';
Combobox.Input.displayName = 'Combobox.Input';
Combobox.List.displayName = 'Combobox.List';
Combobox.Empty.displayName = 'Combobox.Empty';
Combobox.Group.displayName = 'Combobox.Group';
Combobox.Separator.displayName = 'Combobox.Separator';
Combobox.Item.displayName = 'Combobox.Item';
Combobox.Label.displayName = 'Combobox.Label';

export default Combobox;
